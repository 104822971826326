<template>
  <div>
    <b-card>
      <div class="text-center">
        <h3>UTD RS OTISTA</h3>
        <p>Rekapitulasi Darah Karantina</p>
      </div>
      <b-table
        striped
        hover
        small
        :items="darah.items"
        :fields="darah.fields"
      ></b-table>

      <h4 class="mt-5">Jumlah</h4>
      <b-table
        striped
        hover
        small
        :items="jumlah.items"
        :fields="jumlah.fields"
      ></b-table>
      <p class="text-right"><strong>Total: </strong>30</p>
    </b-card>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { BCard, BTable } from "bootstrap-vue";

const darah = ref({
  fields: [
    { key: "no", label: "No" },
    { key: "no_kantong", label: "No Kantong" },
    { key: "komponen", label: "Komponen" },
    { key: "b", label: "Gol Darah" },
    { key: "jumlah", label: "Jumlah" },
    { key: "tgl_aftap", label: "Tgl Aftap" },
    { key: "tgl_produksi", label: "Tgl Produksi" },
    { key: "tgl_expired", label: "Tgl Expired" },
  ],

  items: [
    {
      no: 1,
      no_kantong: "A123",
      komponen: "WB",
      gol_darah: "O+",
      jumlah: 1,
      tgl_aftap: "2024-01-01",
      tgl_produksi: "2024-01-02",
      tgl_expired: "2024-03-01",
    },
    {
      no: 2,
      no_kantong: "B456",
      komponen: "PRC",
      gol_darah: "A-",
      jumlah: 2,
      tgl_aftap: "2024-02-01",
      tgl_produksi: "2024-02-02",
      tgl_expired: "2024-04-01",
    },
  ],
});

const jumlah = ref({
  fields: [
    { key: "jenisKantong", label: "Kenis Kantong" },
    { key: "a", label: "A" },
    { key: "b", label: "B" },
    { key: "ab", label: "AB" },
    { key: "o", label: "O" },
    { key: "total", label: "Total" },
  ],

  items: [
    {
      jenisKantong: "WB",
      a: 1,
      b: 2,
      ab: 3,
      o: 4,
      total: 10,
    },
    {
      jenisKantong: "TR",
      a: 1,
      b: 2,
      ab: 3,
      o: 4,
      total: 10,
    },
    {
      jenisKantong: "QD",
      a: 1,
      b: 2,
      ab: 3,
      o: 4,
      total: 10,
    },
  ],
});
</script>
